/* General styles for the navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #F8F9FA;
  color: #014A76;
  position: relative;
}
.social-icons-mobile{
  display: none;
}

.navbar-logo img {
  height: 60px; /* Adjust logo size */
}

/* Styles for the menu */
.navbar-menu {
  display: flex;
  align-items: center;
  gap: 20px; /* Space between menu items */
}

/* For desktop view */
.navbar-menu a {
  color: #014A76;
  text-decoration: none;
  padding: 10px;
  text-transform: uppercase; /* Make all text uppercase */
}

.navbar-right {
  display: flex;
  align-items: center;
  gap: 20px;
}

.social-icons a {
  color: #014A76;
  font-size: 20px;
  margin: 0 9px;

}

.call-back {
  background-color: transparent;
  border: 1px solid #014A76;
  padding: 8px 15px;
  color: #014A76;
  border-radius: 0px;
  cursor: pointer;
  text-decoration: none;
}

.call-back:hover {
  background-color: #014A76;
  color: #F8F9FA;
}

/* Hamburger menu for mobile */
.hamburger {
  display: none; /* Hidden by default */
  flex-direction: column;
  cursor: pointer;
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: #014A76;
  margin: 4px 0;
  transition: 0.4s;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .navbar-menu {
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    width: 250px;
    background-color: #F8F9FA;
    flex-direction: column;
    align-items: start;
    padding-top: 20vh;
    transform: translateX(100%); /* Hidden by default */
    transition: transform 0.3s ease-in-out;
    z-index: 10;
  }

  .navbar-menu.open {
    transform: translateX(0); /* Slide in from the right when open */
    

  }


  .navbar-menu a {
    padding: 5px 20px;
    font-size: 3.2vh;
  }

  .social-icons-mobile {
    display: flex; /* Make social icons horizontal in sidebar */
    justify-content: space-around;
    width: 100%;
    padding: 10px 10px;

  }
 

  .hamburger {
    display: flex; /* Show the hamburger menu */
  }

  /* Close button styles */
  .close-btn {
    font-size: 30px;
    color: #014A76;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .navbar-right {
    display: none; /* Hide the right section on mobile */
  }
}

/* Hide the close button in desktop view */
.close-btn {
  display: none;
}

@media (max-width: 768px) {
  .close-btn {
    display: block;
  }
}
