@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@font-face {
    font-family: 'Phosery';
    src: url('../Font/Phosery/Phosery.ttf') format('truetype'),
         url('../Font/Phosery/Phosery.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
     font-family: 'Cospiog';
     src: 
          url('../Font/Cospiog/cospiog-italic.otf') format('opentype'),
          url('../Font/Cospiog/cospiog.otf') format('opentype');
     font-weight: normal;
     font-style: normal;
   }
   @font-face {
     font-family: 'Forum';
     src: 
          url('../Font/Forum/Forum-Regular.ttf') format('truetype');
     font-weight: normal;
     font-style: normal;
   }

   .navbar-menu a{
    font-family: 'Forum';
    font-weight: 300;
    font-size: 2.5vh;
    text-transform: uppercase;

   }
   .navbar-menu a:hover,.navbar-menu a:active{
    font-weight: 600;
    font-size: 2.5vh;

   }
 
   .footer-links li a{
    font-family: 'Forum';
    font-weight: 300;
    font-size: 2.5vh;
    text-transform: uppercase;
   }
   

   h1{
     font-family: 'Forum';
     font-weight: 100;
     font-size: 6vh !important;
     text-transform: uppercase;
   }
   h2{
    font-family: 'Forum';
     font-weight: 600;
     font-size: 4vh !important;
     text-transform: uppercase;
   }

   p{

     font-family: "Inter", system-ui;
    
   }

    /* mobile css starts */

  @media (max-width: 768px) {

    h1{
      font-size: 4.5vh !important;
    }
    .footer-links li a, .footer-contact a{
      font-size: 2.2vh;
    }
    
  }



  /* mobile css Ends */

  