.footer {
    background-color: #f8f9fa;
    padding: 20px;
    text-align: center;
    position: relative;
  }
  
  .footer-logo {
    margin-bottom: 20px;
    max-width: 150px;
  }
  
  .footer-middle {
    margin-bottom: 20px;
  }
  
  .footer-links {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 30px;
  }
  
  .footer-links li a ,.footer-contact a {
    text-decoration: none;
    color: #014A76;
  }
 
  .footer-contact p {
    margin: 5px 0;
    color: #666;
  }
  
  .footer-bottom {
    position: absolute;
    bottom: 10px;
    right: 20px;
    font-size: 14px;
    color: #999;
  }
  
  @media (max-width: 768px) {
   .footer-contact{
    margin-bottom: 10vh;
   }
    

  }